.arms-form {
    margin: 0px;
}

.arms-form>div>div {
    display: flex;
    padding-left: 0px;
}

/* TODO: disable submit from uiSchema */
#arms-path-selector>div:nth-child(2) {
    display: none;
}

.arms-form>div>div>* {
    /* padding-right: 10px; */
    padding-left: 0px;
}