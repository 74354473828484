/* React-Grid-Layout overrides */
.react-grid-layoutTM {
    margin: 10px 0;
    background-color: transparent !important;
    min-height: 70px;
}

.react-grid-itemTM {
    background-color: transparent !important;
    border: 1px solid transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-grid-item > .react-resizable-handle {
  background-image: none !important;
}

.react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid #7AB9DB08 !important;
    border-bottom: 2px solid #7AB9DB08 !important;
  }
  .react-grid-item > .react-resizable-handle:hover::after {
    border-right: 2px solid #7AB9DB !important;
    border-bottom: 2px solid #7AB9DB !important;
  }
  .react-grid-item.react-grid-placeholder {
    background-color: #7AB9DB !important;
    opacity: 0.5 !important;
  }

  .react-grid-item.resizing {
    z-index: 3 !important;
    overflow: visible;
  }

  #layout-grid-container::-webkit-scrollbar {
    width: 8px;
  }
