
.rst__lineChildren::after {
  background-color: transparent !important;
  border-color: #7ab9db !important;
  border-left-style: dashed;
  border-width: 1px;
  opacity: 0.5;
}


.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  background-color: transparent !important;
  border-color: #7ab9db !important;
  border-width: 1px;
  opacity: 0.5;
}

.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  border-left-style: dashed;
}

.rst__lineHalfHorizontalRight::before {
  border-top-style: dashed;
}

.rst__row > * {
  overflow: visible !important;
  max-height: 100%;
}

.rst__row > * > * {
  overflow: visible !important;
  max-height: 100%;
}