:root {
    --tooltip-bg-color: #353447;
  }

.tooltip {
    background-color: var(--tooltip-bg-color);
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 100;
    color: #f5f5f5;
}

.mb {
    margin: 10px 7.5px !important;
}

.mt {
    margin: 20px 7.5px !important;
}

.mlr-start {
    margin: -2px 0 0 0 !important;
}

.mlr {
    margin: 8px 0 0 0 !important;
}

.m-auto {
    margin: 1rem !important;
}

.container {
    display: flex;
    min-height: 24px;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 8px 15px;
}

.submitButton {
    display: none;
}

.item {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    display: flex;
    box-sizing: border-box;
    user-select: none;
}

.item :hover { 
    background-color: #eee;
}

.divRef {
    width: fit-content;
    margin: 0;
    display: inline;
}

.tooltip-container {
    margin: 0.6rem;
    transition: opacity 0.3s;
  }
  
  .tooltip-arrow {
    height: 1rem;
    position: absolute;
    width: 1rem;
  }
  
  .tooltip-arrow::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    width: 0;
  }
  
  .tooltip-arrow::after {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }
  
  .tooltip-arrow[data-placement*='right'] {
    height: 1rem;
    left: 0;
    margin-left: -0.7rem;
    width: 1rem;
  }
  
  .tooltip-arrow[data-placement*='right']::before {
    border-color: transparent var(--tooltip-bg-color) transparent transparent;
    border-width: 0.5rem 0.4rem 0.5rem 0;
  }
  
  .tooltip-arrow[data-placement*='right']::after {
    border-color: transparent var(--tooltip-bg-color) transparent transparent;
    border-width: 0.5rem 0.4rem 0.5rem 0;
    left: 6px;
    top: 0;
  }
  
  .tooltip-menu--checklist label {
    cursor: pointer;
    flex-grow: 2;
  }

  .tooltip-menu--cta {
    cursor: pointer;
    text-decoration: underline;
  }